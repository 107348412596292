.footer {
   
    @include color(background-color, 'background-alt-dark');
    padding-left:0;
 
    &.container {
        padding-right: unset;
    }

    .social-links {
        align-items: center;
        padding-bottom: 10.75rem;
        padding-top: 0;

        @include breakpoint(M){
            padding-bottom: 9.6rem;
        }

        @include breakpoint(XS) {
            width: 100%;
            padding-top: unset;
            padding-bottom: 2rem;
            padding-left: 0.75rem;
        }
        .button.secondary:after {
            display: none;
        }
        .button.secondary {
            margin: unset !important;

            @include breakpoint(XS) {
                margin: 0 0.5rem;
            }
        }
    }
}

.copyright{
    @include breakpoint(S){
        min-height: 15.857142857142858rem;
        .container.flex.items-center.justify-between{
            width: unset;
            text-align: left;
            justify-content: flex-start;
            .items-center{
                text-align: left;
                align-items: flex-start!important;
                align-self: flex-start;
                a{
                    padding-left:0;
                }
                ul{
                    li{
                        text-align: left;
                        a{
                            padding-left:0;
                        }
                    }
                }
            } 
        }
    }
    .footer-logos{
        width: 30%;
        height: 15rem;
        clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
        background: white;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-left: 7.5rem;
        @include breakpoint(S){
            width: 16.571428571428573rem;
            height: 12.071428571428571rem;
            padding-left:6.5rem;
        }
        .slick-controls{
            padding-top:0.5rem;
            svg{
                height:2.591875rem;
                color:$color-body;
            }
        }
        .js-footer-logos{
            width: 8.375rem;
            height: 8.375rem;
            position: relative;
            @include breakpoint(S){
                width: 7.428571428571429rem;
                height: 7.428571428571429rem;
            }
            
            img{
                position:absolute;
                width:100%;
                height:100%;
                object-fit: contain;
            }
            .single-logo{
                width: 8.375rem;
                height: 8.375rem;
                position: relative;
            }
        }
    }
}

.footer-logo {
    width: 35%;
    padding-right: 2rem;
    @include color(background-color, 'body');
    clip-path: polygon(0 0, 100% 0%, 55% 100%, 0% 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    top:-3rem;

    @include breakpoint(S) {
        width: 75%;
        height: 14.5rem;
        padding-right: 0;
    }
    svg {
        width:10.5625rem;
        height:10.5625rem;
        margin-left: -7rem;
        @include breakpoint(M) {
            margin-left: -1rem;
        }
        @include breakpoint(S) {
            margin-left: -7rem;
        }
    }
}

.footer-content {
    width: 50%;

    @include breakpoint(S) {
        width: 100%;
        padding:2rem;
        span:last-child{
            margin-bottom: unset;
        }
    }
    .footer-menu {
        @include breakpoint(XS) {
            width: 100%;
        }
    }
}

.footer-menu {
    margin-bottom: 2rem;
    padding-right: 1rem;

    // Check if browser supports grid (IE doesn't support support queries themselves so it'll ignore everything in here)
    @supports (display: grid) {
        padding-right: 0;
        width: 100%;
    }
    
    .footer-menu-wrap {
        @include breakpoint(XS-up) {
            display: block !important; // this is in case someone closes it on mobile and goes to a larger screen
        }
        @include breakpoint(S) {
            display: block !important; // this is in case someone closes it on mobile and goes to a larger screen

        }
    }
    ul {
        width: 100%;
        display: grid;
        // Make a 2 column grid where the columns have an auto min-width but a max-width of 18rem so they don't stretch ridiculously far
        grid-template-columns: repeat(2, minmax(auto, 18rem));
        column-gap: 2rem;
        row-gap: 1rem;
        padding-right: 1rem;
        @include breakpoint(L){
            grid-template-columns: repeat(2, minmax(auto, 10rem));
        }
        @include breakpoint(S){
            row-gap: 0.8571428571428571rem;
            grid-template-columns: repeat(2, minmax(auto, 18rem));
        }

        @supports (display: grid) {
            margin-bottom: 0;
            padding-right: 0;
        }
        li {
            margin-bottom: 1rem;
            padding-right: 1rem;
            @include breakpoint(S){
                a{
                    padding:0;
                }
            }

            @supports (display: grid) {
                margin-bottom: 0;
                padding-right: 0;
            }
            @include breakpoint(XS) {
                margin-bottom: 0;
            }
        }
    }
    a {
        display: block;
        @include color(color, 'body');
        @include size-M;
        font-weight: 500;

        @include breakpoint(XS) {
            padding: 1rem 0;
        }
        &:hover {
            @include color(color, 'accent-primary');
        }
    }
}

.mob-footer-menu-toggle {
    width: 100%;

    .button.icon {
        display: none;

        @include breakpoint(XS) {
            display: flex;
        }
    }
    @include breakpoint(XS) {
        @include size-L;

        &.active {
            @include color(color, 'accent-primary');

            .button {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
    }
}

.footer-content-top {
    display: grid;
    // Basically this is saying "if there's enough space, fill in columns at comfortable width with a min-width of 16rem
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 2rem;
    padding-top: 3.75rem;
    @include breakpoint(S){
        padding-top: 0rem;
    }
 
}
.footer-content-bottom {
    padding-bottom: 4.25rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 18rem));
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
    row-gap: 1rem;
    @include size-M;
    @include breakpoint(L){
        grid-template-columns: repeat(2, minmax(auto, 10rem));
    }
    .color-accent-primary{
        @include color(color, 'accent-primary');
    }
    @include breakpoint(S) {
        padding-top: 1.5rem;
        grid-template-columns: repeat(2, minmax(auto, 18rem));
        padding-bottom: 0.8571428571428571rem;
        display: flex;
        flex-direction: column;
    }
    span {
        //margin-right: 2rem;
        .color-white{
            @include color(color, 'body');
        }
        @include breakpoint(S) {
            margin: 0 0 1.5rem 0;
            width: 100%;
        }
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
    }
}