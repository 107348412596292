//Styling for FAQs Plugin
//Note Important tags are used just to plugin directly styling tags
@import "variables";
@import "mixins";
@import "placeholders";
@import "../base/colors/_colors-core";
@import "../base/typography/type-scales/type-scales";

.page-template-default{
    #epkb_search_terms{
        background: transparent!important;
        border-color: transparent!important;
        @include breakpoint(S){
            background: white!important;
            border-color: white!important;
        }
    }
    .eckb-kb-template{
        margin:0!important;
        padding:0!important;
        #epkb-main-page-container{
        margin:0!important;
        padding:0!important;
            &.epkb-basic-template{
                margin:0!important;
                padding:0!important;
                display: flex;
                .epkb-full{
                    display: flex;
                    @include breakpoint(S){
                        flex-direction: column;
                    }
                    .three-col.eckb-categories-list{
                        display: flex;
                        flex-direction: column;
                        padding-top: 5rem;
                        .section_light_shadow.epkb-top-category-box{
                            display: flex!important;
                            flex-direction: row;
                            width:auto;
                            padding:2rem;
                            border: 2px solid rgba(21, 19, 29, 0.1);
                            box-sizing: border-box;
                            border-radius: 16px!important;
                            background-color: transparent;
                            box-shadow:none!important;
                            border-color: rgba(21, 19, 29, 0.1)!important;
                            border-width:2px!important;
                            margin-left: 3rem!important;
                            margin-right: 3rem!important;
                            @include breakpoint(XS){
                                margin-left: 0rem!important;
                                margin-right: 0rem!important;
                            }
                           
                            .epkb-section-body{
                                @include breakpoint(XS){
                                    padding-left:0!important;
                                }
                                .epkb-main-category.epkb-articles{
                                    padding-top:3.15625rem;
                                    .epkb-article-level-1 {
                                        &::before{
                                            display: none!important;
                                        }
                                    }
                                }
                            }
                            .eckb-article-title__text{
                                font-weight: 500!important;
                            }
                            .epkb-section-body .epkb-articles-coming-soon{
                                font-style: initial;
                                &::before{
                                    display: none!important;
                                }
                            }
                            .section-head.section_divider{
                                background-color: transparent!important;
                                width:6rem!important;
                                height:6rem!important;
                                padding-top: 0rem!important;
                                padding-bottom: 0rem!important;
                                .epkb-category--top-cat-icon{
                                    position: relative;
                                    width: 50rem;
                                    @include breakpoint(XL){
                                        width: 25rem;
                                    }
                                    @include breakpoint(L){
                                        width: 20rem;
                                    }
                                    @include breakpoint(S){
                                        width: 15rem;
                                    }
                                }
                                @include breakpoint(XS){
                                    width: 2.5rem !important;
                                    height:2.5rem !important;
                                }
                              
                                .epkb-cat-icon{
                                    width:6rem;
                                    height:6rem;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    border: 2px solid rgba(21, 19, 29, 0.1);
                                    box-sizing: border-box;
                                    border-radius: 1e+06px;
                                    @include breakpoint(XS){
                                        width:2.5rem;
                                        height:2.5rem;
                                    }
                                    &::before{
                                        font-size: 2.5rem;
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        top: 1.5rem;
                                        bottom: 0;
                                        color: $color-accent-primary;

                                        @include breakpoint(XS){
                                            font-size: 1.5rem;
                                            top: 0.35rem;
                                        }
                                            
                                    }
                                }
                                .epkb-cat-name{
                                    position: absolute!important;
                                    left: 0rem!important;
                                    top: 0rem!important;
                                    left: 7.4rem!important;
                                    top: 0rem!important;
                                    text-align: left;
                                    font-weight: 600;
                                    @include size-3XL;
                                    @include breakpoint(L){
                                        width: 15rem!important;
                                    }
                                    @include breakpoint(S){
                                      
                                        font-size: 15px!important;
                                        margin-left: 1rem!important;
                                    }
                                    @include breakpoint(XS){
                                        left: 2.4rem !important;
                                        top: 0.5rem !important;
                                    }
                                    
                                }
                                
                            }
                        }
                    }
                    .epkb-doc-search-container{
                        max-width:27.625rem!important;
                        padding:3rem!important;
                        background-color:#202024!important;
                        padding-top:4rem!important;
                        min-height: 51.375rem!important;
                        height: 100vh!important;
                        position: sticky;
                        top: 4.5rem;
                        margin-bottom: 0!important;
                        @include breakpoint(S){
                            position:unset;
                            max-width: 100%!important;
                            width:100%;
                            height: auto!important;
                            min-height: unset!important;
                            margin-bottom: 0!important;
                        }
                        .epkb-doc-search-container__title{
                            @include size-2XL;
                            font-weight: 600!important;
                            text-align: left!important;
                            font-size:28px!important;
                            @include breakpoint(S){
                                text-align: center!important;
                            }
                        }
                        .epkb-search{
                            width:100%!important;
                        }
                        .epkb-search-box{
                            background:white;
                            border-radius: 1e+06px!important;
                            @include breakpoint(S){
                                background: transparent!important;
                                border-radius: 12px!important;
                            }
                            input[type=text]{
                                padding: 1rem 1.25rem 1rem 1.25rem!important;
                                @include size-S;
                            }
                            input:-webkit-autofill {
                               // -webkit-box-shadow: 0 0 0 1000px white inset !important;
                            }
                            &.active{
                                display: flex!important;
                                @include breakpoint(S){
                                    display: block!important;
                                }
                            }
                        }
                        #epkb_search_results{
                            .epkb-search-results-message{
                                color: $color-accent-primary;
                                font-feature-settings: 'pnum' on, 'lnum' on;
                            }
                            ul li:before{
                                display: none;
                            }
                            background-color: transparent!important;
                            border: 2px solid rgba(255, 255, 255, 0.1)!important;
                            box-sizing: border-box;
                            border-radius: 16px;
                            padding: 1.5rem!important;
                            color:white!important;
                            margin-top:1.5rem;
                            .epkb_search_results__article-title__icon.epkbfa.ep_font_icon_document{
                                color:white;
                            } 
                            .epkb_search_results__article-title__text{
                                color:white;
                            }
                            ul li{
                                padding-top:1.5rem!important;
                            }
                        }
                    }
                }
            }
        }
    }
}
#epkb-main-page-container.epkb-basic-template .epkb-doc-search-container .epkb-search .epkb-search-box .epkb-search-box_button-wrap button{
    text-shadow: none!important;
    font-weight: 600!important;
    color:$color-body!important;
    border-radius: 1e+06px!important;
    padding: 12px 24px!important;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 14px!important;
    height:4rem;
}
#epkb-main-page-container.epkb-basic-template .epkb-doc-search-container .epkb-search .epkb-search-box input[type=text]{
    padding: 1.25rem 1rem 1.25rem 1rem!important;
    height:4rem!important;
}

//FAQs Single Styles 

.epkb_post_type_1-template-default.single.single-epkb_post_type_1{
    #eckb-article-header{
        display: none;
        .eckb-article-byline{
            display: none!important;
        }
    }
    .eckb-breadcrumb-outline{
        @include breakpoint(S){
            display: none!important;
        }
    }
    background-color: #F2F2F3!important;
    #eckb-article-body{
        background: #FFFFFF;
        margin-top:7.5rem!important;
        margin-bottom:7.5rem!important;
        box-shadow: 4px 8px 24px rgba(0, 10, 44, 0.075);
        border-radius: 2rem!important;
        @include breakpoint(S){
            margin-top: 2rem!important;
            margin-bottom: 2rem!important;
        }
        #eckb-article-content{
            border-radius: 2rem!important;
            padding:5.125rem!important;
            @include breakpoint(S){
                overflow: hidden;
                padding: 2rem!important;
            }
            ul li:before{
                display: none;
            }
            .eckb-breadcrumb-label{
                display: none!important;
            }
            .eckb-breadcrumb-nav{
                li:last-child{
                    display: none!important;
                }
                li:nth-last-child(2){
                    span{
                        &::before{
                            display: none;
                        }
                    }
                }
            }
            .epkb-article-navigation-container{
                display: none!important;
            }
            .eckb-navigation-button{
                letter-spacing: 0.125em;
                text-transform: uppercase;
                font-feature-settings: 'pnum' on, 'lnum' on;
                font-weight: 600;
                @include color(color, 'body');
                color:$color-body!important;
                padding-right:2rem!important;

            }
            .eckb-breadcrumb-nav{
                .eckb-breadcrumb-link{
                    span{
                        color:$color-body!important;
                        font-weight: 700!important;
                    }
                }
            }
            #eckb-article-content-header-row-3 .eckb-article-content-header-row-left-group{
                padding-top:2rem;
            }
            .eckb-meta-data-feature-value{
                padding-right:2rem!important;
            }
            .eckb-print-button-container{
                .eckb-toolbar-button-text{
                    letter-spacing: 0.125em;
                    text-transform: uppercase;
                    font-feature-settings: 'pnum' on, 'lnum' on;
                    font-weight: 600;
                }
            }
        }
    }
}
#eckb-categories-archive__body__content{
    ul li:before{
        display: none !important;
    }
}
.epkb-main-category.epkb-articles{
    li:before{
        display: none !important;
    }
}
#eckb-categories-archive-container-v2{
    .eckb-category-archive-style-2 {
        .eckb-article-container {
            .eckb-article-header{
                .eckb-article-metadata{
                    .eckb-article-byline {
                        display: none !important;
                    }
                }
            }
        }
    }
}
