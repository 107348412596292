.single-post {
    .banner-block {
        h1 {
            .subtitle {
                margin-top: 0.5rem;
            }
        }
    }
}
.blog, .single{
    .banner-block{
        .banner-content-block{
            text-align: center;
            max-width: 50.0625rem;
        }
    }
}

.blog-post {
    p,
    li {
        font-family: $font-stack-headings;
        max-width: 50rem;
        margin: 0 auto;

        @include breakpoint(S) {
            max-width: calc(100vw - 4rem);
        }
    }
    p {
        margin-bottom: 1.75rem;
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-bottom: 2rem;
        max-width: 50rem;

        @include breakpoint(S) {
            max-width: calc(100vw - 4rem);
        }
    }
    h2 {
        margin-top: 4.25rem;
        @include breakpoint(S){
            margin-top:2rem;
        }
    }
    h3,
    h4 {
        margin-top: 1.75rem;
    }
    blockquote {
        margin-top: 6rem;
    }
    blockquote,
    .wp-block-embed,
    ul,
    ol,
    hr {
        margin-bottom: 1.75rem;
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(S) {
            max-width: calc(100vw - 4rem);
        }
    }
    .wp-block-image {
        figcaption {
            margin-top: 0;
            font-family: $font-stack-headings;
        }
    }
}

.post-actions {
    max-width: 50rem;
    margin: 6rem auto;

    @include breakpoint(XS) {
        margin: 4rem auto;
    }
}

.more-articles {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem;
    @include color(background-color, 'background-alt');
    margin-bottom: -3rem;

    @include breakpoint(XS) {
        padding-top: 4.5rem;
        padding-bottom: 3rem;
    }
}


.posts-grid {
    margin-left: -1rem;
    margin-right: -1rem;
    display:  grid;
    grid-template-columns: repeat(3, 1fr);
    gap:2rem;
    margin-bottom: 6rem;
    @include breakpoint(M){
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(S){
        grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint(XS){
        padding-bottom: 10rem;
    }
    .feat-post{
        grid-column: 1 / span 3;
        width: 100%;
        margin-bottom: 4rem!important;
        @include breakpoint(M){
            grid-column: 1 / span 2;
        }
        @include breakpoint(S){
            grid-column: 1 / span 1;
        }
    }
    
    &.rel-posts {
        padding-top: 3.5rem;
    }
    .post-item{
        position: relative;
        min-height: 28rem;
        min-width: 20rem;
        width:100%;
        &.third{ 
            @include breakpoint(XS){
                .post-item-img{
                    width: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .post-item-content{
        @include color(background-color, 'accent-primary');
        box-shadow: 4px 8px 24px rgba(0, 10, 44, 0.075);
        border-radius: 16px;
        min-height: 17.125rem;
        width: calc(100% - 3rem);
        right: 0;
        position: relative;
        margin-top: -3rem;
        padding:2rem;
        left:3rem;
      
        h3{
            font-weight: 600;
        }
        .tags-container{
            position: relative;
            &::after{
                content: "";
                position: absolute;
                right:0;
                width:20%;
                top:0;
                height:2rem;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+72,ffffff+100&0+72,1+100 */
              
            }  
        }
        .overline{
            margin-bottom: 1rem;
            margin-bottom: 1rem;
            height: 3rem;
            overflow-x: scroll;
            overflow-y: clip;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            position: relative;
            display: flex;
            &::-webkit-scrollbar{
                display: none;
            }
            
                
        }
        .icon.button{
            @include color(background-color, 'background');
            margin-top: 0.75rem;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .cat-link{
            @include color(background-color, 'body');
            border-radius: 1e+06px;
            padding: 0.5rem 0.75rem;
            margin-bottom: 1rem;
            font-weight: 700;
            text-transform: none;
            letter-spacing: inherit;
            font-feature-settings: 'pnum' on, 'lnum' on;
            margin-right:0.5rem;
            white-space: nowrap;
            top:0.5rem;
            width: fit-content;



        }
    }
    .post-item-img {
        display: block;
        height:15.5rem;

        img {
            height: 100%;
            max-width: calc(100% - 3rem);

            @supports (aspect-ratio: initial) {
                aspect-ratio: 4 / 3;
            } 

            @include breakpoint(S) {

                @supports not (aspect-ratio: initial) {
                    height: 10rem;
                } 
            }
            @include breakpoint(XS) {
            
                @supports not (aspect-ratio: initial) {
                    height: 15rem;
                } 
            }
        }
    }
    .post-item {
        margin-left: 0rem;
        margin-right: 0rem;
        margin-bottom: 0rem;

        @include breakpoint(XS) {
           

            &:last-child {
                margin-bottom: 0;
            }
        }
        &.third {
           // width: calc(33.333% - 2rem);
            flex-grow: unset;

            @include breakpoint(S) {
                width: calc(100% - 4rem);
                flex-direction: column;
                margin-left: 2rem;
                margin-right: 2rem;

            }
            @include breakpoint(XS) {

                .post-item-img {
                    margin-right: 2rem;
                    margin-bottom: 0;

                    
                }
            }
        }
        h2,
        h3,
        h4 {
            a {
                @include color(color, 'body'); 

                &:hover {
                    @include color(color, 'background');
                }
            }
        }
        &.feat-post {
            width: 100%;
            flex-basis: unset;

            @include breakpoint(S){
                height:unset;
            }

            .post-item-img {
                width: 50%;
                position: relative;
                height:100%;
                img{
                    width:100%;
                }

                @include breakpoint(S) {
                    width: 100%;
                    height: 25rem;
                    margin-bottom: 2rem;

                    img{
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
            .feat-post-content {
                width: 60%;
                max-width: 40rem;
                padding-left: 2rem;
                padding-top: 3.5rem;
                padding-bottom: 3.5rem;

                @include breakpoint(S) {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}

.post-details {
    //margin-bottom: 9rem;
    @include color(background-color, 'accent-primary');
    box-shadow: 4px 8px 24px rgba(0, 10, 44, 0.075);
    border-radius: 16px;
    margin-left:7.5rem;
    margin-right:7.5rem;
    top: -5rem;
    position: relative;
    z-index: 1;
    align-items: normal;
    margin-bottom: 2rem;
    &.no-image{
        top:0;
    }
    .post-excerpt{
        padding:2rem;
        display: flex;
        align-items: center;
    }
    @include breakpoint(ML){
        margin-left:2rem;
        margin-right:2rem;
    }
    @include breakpoint(M){
        flex-direction: column;
    }
    @include breakpoint(S){
        margin-left:2rem;
        margin-right:2rem;
        margin-top:0;
    }
    @include breakpoint(S){
        .post-excerpt{
            padding:2rem;
        }
    }
    .post-info{
        @include color(background-color, 'background');
        padding-top:4.375rem;
        padding-bottom:4.375rem;
        border-radius: 16px 0px 0px 16px;
        padding-left:6rem;
        clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
        height: auto;
        @include breakpoint(M){
            margin-right: 0!important;
        }
        @include breakpoint(S){
            clip-path: unset;
            border-radius: 16px 16px 0 0;
            padding:4rem;
            padding-left:2rem;
            margin-bottom: 0;
        }
    }
    &.thin {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 9rem;
       
    
        @include breakpoint(XS) {
            margin-bottom: 4.5rem;

            .post-share {
                padding-top: 1.5rem;
            }
        }
    }
    &.has-excerpt {
        .post-info {
            min-width: 34rem;
            padding-right: 4.25rem;
            margin-right: 4.25rem;
          

            @include breakpoint(M) {
                min-width: 20rem;
            }
            @include breakpoint(XS) {
                min-width: auto;
                width: 100%;
                padding-right: 0;
                margin-right: 0;
                border-right: 0;
                padding-right: 1rem;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    .intro-content {
        p {
            @include size-L;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.event-details {
        @include breakpoint(XS) {
            padding-top: 2.25rem;
            margin-bottom: 4.5rem;

            .post-info {
                padding-bottom: 2rem;
            }
        }
    }
}

.post-info {
    strong {
        padding-right: 0.5rem;
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
        &:after {
            content: ",";
            padding-right: 0.25rem;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.post-share {
    .button {
        margin-left: 1rem;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 1e+06px;

    }
}

.posts-nav {
    border-top: 1px solid;
    @include color(border-color, 'body-10');
    padding: 3rem 0;

    @include breakpoint(XS) {
        padding: 0;
    }
    a {
        @include color(color, 'body');

        @include breakpoint(XS) {
            padding: 1.5rem 2rem;
            border-bottom: 1px solid;
            @include color(border-color, 'body-10');
        }
        &:hover {
            @include color(color, 'accent-primary');

            .icon {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
        &.prev {
            .icon {
                margin-right: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(-0.25rem);
                }
            }
        }
        &.next {
            .icon {
                margin-left: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(0.25rem);
                }
            }
        }
    }
}

.loadmore-wrapper {
    padding-top: 2rem;
    display: none;
}

.offer-share {
    .bordered {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');
    }
}

.addeventatc {
    cursor: pointer;
    position: relative;

    @include breakpoint(XS) {
        margin-bottom: 1.5rem;
    }
    &:hover,
    &.addeventatc-selected {
        .atc-button {
            @include color(color, 'accent-primary');
        }
    }
    .atc-button {
        @extend %transition;

        svg {
            margin-left: 0.75rem;
        }
        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"%3E%3Ccircle cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 17.5L12 6.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.5 12L6.5 12"/%3E%3C/svg%3E');
            margin-left: 0.75rem;

            @include breakpoint(XS) {
                width: 21px;
                height: 21px;
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"%3E%3Ccircle cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 17.5L12 6.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.5 12L6.5 12"/%3E%3C/svg%3E');
            }
        }
    }
    .copyx {
        display: none;
    }
    .addeventatc_dropdown {
        position: absolute;
        @include color(background-color, 'background');
        border: 1px solid;
        @include color(border-color, 'body');
        margin-top: 0.75rem;
        min-width: 14rem;
        display: none;

        span {
            display: block;
            padding: 1.25rem 1rem;
            line-height: 1;
            border-top: 1px solid;
            @include color(border-color, 'body');
            @extend %transition;

            em {
                @extend %transition;
                font-size: 0.75rem;
            }
            &:first-child {
                border-top: 0;
            }
            &:hover {
                @include color(background-color, 'accent-primary');
                @include color(color, 'background');

                em {
                    @include color(color, 'background');
                }
            }
        }
    }
}

.post-feat-img {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}