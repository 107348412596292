//Define type styles that can be used as utility classes or mixins elsewhere.

@mixin heading-1 {
    font-family: $font-stack-headings;
    font-weight: 500;
    @include size-3XL;
    line-height: 1.1;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin heading-2 {
    font-family: $font-stack-headings;
    font-weight: 500;
    @include size-2XL;
    line-height: 1.2;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin heading-3 {
    font-family: $font-stack-headings;
    font-weight: 600;
    @include size-XL;
    line-height: 1.33;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin heading-4 {
    font-family: $font-stack-headings;
    font-weight: 600;
    @include size-L;
    line-height: 1.33;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin heading-5 {
    font-family: $font-stack-headings;
    font-weight: 600;
    @include size-M;
    line-height: 1.33;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin subtitle-1 {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-L;
    line-height: 1.5;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin subtitle-2 {
    font-family: $font-stack-headings;
    font-weight: 500;
    @include size-M;
    line-height: 1.5;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin body-L {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-L;
    line-height: 1.5;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin body-M {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-M;
    line-height: 1.5;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin body-S {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-S;
    line-height: 1.5;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin body-XS {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-XS;
    line-height: 1.5;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-transform: none;
}

@mixin overline {
    font-family: $font-stack-primary;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    @include size-XS;
    line-height: 1.5;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

@mixin button-text {
    font-family: $font-stack-primary;
    font-weight: 600;
    @include size-M;
    line-height: 1.5;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

@mixin label {
    font-family: $font-stack-headings;
    font-weight: 700;
    @include size-XS;
    line-height: 1.5;
    letter-spacing: 0;
    font-feature-settings: 'pnum' on, 'lnum' on;
}


//Define utility classes here (TODO: merge _headings.scss into this section)

.subtitle-1 {
    @include subtitle-1;
}

.subtitle-2 {
    @include subtitle-2;
}

.body-l {
    @include body-L;
}

.body-m {
    @include body-M;
}

.body-s {
    @include body-S;
}

.body-xs {
    @include body-XS;
}

.overline {
    @include overline;
}

.button-text {
    @include button-text;
}

.label {
    @include label;
}